var Olp = {}

Olp = {
  form_fields: function(){
    $('input.form-control').focus(function (){
      $(this).parent().addClass('is-focused')
    })
    $('input.form-control').blur(function (){
      if ($(this).val().length == 0){
        $(this).parent().removeClass('is-focused')
      }
    })
  },
  trim_fields: function(){
    $('input:text, textarea').on('change blur', function(){
      ele = $(this)
      ele.val(ele.val().trim())
    })
  }
}

$(window).on('load',function(){
  Olp.form_fields()
  Olp.trim_fields()
  $('input.form-control').first().focus()
})
